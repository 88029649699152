const utils = {

	DO_BRAND : false,

	TMPL : window.TMPL || function () { return ''; },

	mapBrandFromSubDomain: (str) => {
		const brand = 'Tillr';
		const env = window.TLRAPP.UrlPrefix.length ? window.TLRAPP.UrlPrefix : '';
		const sub = str.substring(str.indexOf('//') + 2, str.indexOf('.'));
		const brands = {
			'pdsc': 'PDSC'
		};
		if (!env.length) {
			if (brands[sub]) {
				return {
					ref: sub,
					name: brands[sub]
				};
			}
		}
		if (sub.match(env)) {
			const subSub = sub.replace(env, '');
			if (brands[subSub]) {
				return {
					ref: subSub,
					name: brands[subSub]
				};
			}
		}
		return brand;
	},

	setAttr : (el, attr, value) => el.setAttribute(attr, value),

	removeAttr : (el, attr) => el.removeAttribute(attr),

	addClass : (el, classes) => {
		const names = classes.split(' ');
		for (let i = 0, x = names.length; i < x; i++) {
			el.classList.add(names[i]);
		}
	},

	removeClass : (el, classes) => {
		const names = classes.split(' ');
		for (let i = 0, x = names.length; i < x; i++) {
			el.classList.remove(names[i]);
		}
	},

	doRedirect : (win, url) => {
		win.location.href = url;
		return win;
	},

	togglePassword : (el) => {
		const input = el.previousElementSibling;
		const type = input.getAttribute('type');
		utils.setAttr(input, 'type', type === 'password' ? 'text' : 'password');
	},

	validateMatch : (one, two)  => one.value === two.value,

	validateLength : (el, len = null) => {
		if (!len) {
			return el.value.length;
		}
		return el.value.length === len;
	},

	validateEmail : (el) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	return re.test(el.value);
	},

	toggleErrorMessage : (els, type) => {
		const classes = 'error--validation error--server error--unauthorised';
		const divs = Object.prototype.toString.call(els).indexOf('rray') !== -1 ? els : [els];
		for (let i = 0, x = divs.length; i < x; i++) {
			if (divs[i]) {
				utils.removeClass(divs[i], classes);
				if (type) {
					utils.addClass(divs[i], `error--${type}`);
				}
			}
		}
	},

	sortByKey : (array, key) => {
		return array.sort(function(a, b) {
			let x = a[key]; let y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	},

	detectIE : () => {
        const ua = window.navigator.userAgent;
        
        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        
        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        
        // other browser
        return false;
	},
	
	isSupported : () => {
		const klass = 'outdated-browser--show';
		const body = document.body;
		if (utils.detectIE()) {
			body.classList.add(klass);
		}

		const btnOne = document.getElementById('browser-upgrade-on');
		const btnTwo = document.getElementById('browser-upgrade-off');
	
		if (btnOne && btnTwo) {
			btnOne.addEventListener('click', () => {
				body.classList.add(klass)
			}, false);
			btnTwo.addEventListener('click', () => {
				body.classList.remove(klass)
			}, false);
		}
	}

};

export default utils;
