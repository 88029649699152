import '../../vendor/template-engine';
import Navigo from 'navigo';
import utils from '../../utils/utils.js';

class RegistrationClass {

    constructor() {

        // settings
        this.delayShowingPage = 500;
		this.myUrl = `https://${window.TLRAPP.UrlPrefix}assessment.licensingsavi.com`;
        this.setPasswordUrl = `https://${window.TLRAPP.UrlPrefix}credentials.licensingsavi.com/setpassword`;
        this.registrationUrls = {
            base: `https://${window.TLRAPP.UrlPrefix}registrations.licensingsavi.com/`,
            registration: `Registrations/`,
            invitation: `Invitations/`,
            signup: `SignUps/`,
        };
		this.regSettings = this.regSettings || {};
		this.passwordResetSuccess = null;

		// branding
		this.branding = {
			local: {
				// PDSC
				740: {
					brand: 'PDSC',
					registrationTemplate: 'registrationWithCompanyTemplate'
				}
			},
			uat: {
				// PDSC
                20122: {
                    brand: 'PDSC',
                    registrationTemplate: 'registrationWithCompanyTemplate'
				},
				// LSAVI
				20126: {
                    brand: 'PDSC',
				},
				// Police
				20127: {
					brand: 'PDSC',
				},
				// FitForBusiness
				20131: {
					brand: 'PDSC',
				},
			},
			demo: {},
			prod: {
				// PDSC
				744: {
					brand: 'PDSC',
					registrationTemplate: 'registrationWithCompanyTemplate'
				},
				// LSAVI
				748: {
					brand: 'PDSC',
				},
				// FitForBusiness
				758: {
					brand: 'PDSC',
				},
			}
		};
		this.referrer = document.referrer;
		this.pageBrand = 'tillr';

		// general
		this.content = this.$$('content');
		this.logo = this.$$('title');
		this.loader = this.$$('tillr-loading');
		this.footer = document.querySelector('footer');
		this.token = null;

		// set password
		this.setPasswordPage = {};

		// registration/invitation
		this.registrationPage = {};
		this.registrationData = {};

		// router
		const _root = null;
		const useHash = true;
		const hash = '#!';
		this.router = new Navigo(_root, useHash, hash);

	}

	$$ (ref) {
		return document.getElementById(ref);
	}

	init () {
		this.router

		  .on('/registration/:id', (params, query) => {
		  	this.initRegistrationPage('registration', params, query);
		  })
		  .on('/invitation/:tenant_id', (params, query) => {
		  	this.initRegistrationPage('invitation', params, query);
		  })
		  .on('/verify/:tenant_id', (params, query) => {
			this.initRegistrationPage('signup', params, query);
		  })
		  .on('/registrationnotrequired', () => {
		  	this.initRegistrationNotRequiredPage(this.regSettings);
		  })
		  .on('/registrationrequired', () => {
		  	this.initRegistrationRequiredPage(this.regSettings);
		  })

		  .on('/setpassword/:tenant_id', (params) => {
			this.initSetPasswordPage(params);
		  })
		  .on('/setpassword/:tenant_id/success', (params) => {
			this.initGenericPage('setPasswordSuccessTemplate');
		  })
		  .on('/setpassword/:tenant_id/error', (params) => {
			this.initGenericPage('setPasswordErrorTemplate');
		  })

		  .on('/error', () => {
			this.initGenericPage('errorTemplate');
		  })
		  .on('/notfound', () => {
			this.initGenericPage('notFoundTemplate');
		  })
		  .on('/alreadyaccepted', () => {
			this.initGenericPage('alreadyAcceptedTemplate');
		  })
		  .on('/', () => {
			this.initRootPage();
		  })
		  .notFound(() => {
			this.initGenericPage('notFoundTemplate');
		  })

		  .resolve();
	}

	brandTemplate () {
		if (!utils.DO_BRAND || !this.regSettings) {
			return;
		}
		const env = window.TLRAPP.UrlPrefix.length ? window.TLRAPP.UrlPrefix : 'prod';
		const ref = this.branding[env][this.regSettings.tenantId] || null;
		if (ref && ref.brand && ref.brand.length) {
			/* set branding */
			this.pageBrand = ref.brand.toLowerCase().replace(/\s/g, '');
			this.logo.innerHTML = ref.brand;
			document.body.classList.add(`brand-${this.pageBrand}`);
		}
	}

	writeTemplate (templateId, model, dest) {
		dest.innerHTML = utils.TMPL(templateId, model);
	}

	showPageElements () {
		window.setTimeout(() => {
			utils.addClass(this.footer, 'fade-in');
			utils.addClass(this.loader, 'fade-out');

			utils.isSupported();
		}, this.delayShowingPage);
	}

	initRootPage () {
		this.visitMy();
	}

	initGenericPage (template) {
		this.brandTemplate();
		this.writeTemplate(template, {}, this.content);
		this.content.querySelector('button#action').addEventListener('click', () => this.visitMy(), false);
		this.showPageElements();
	}

	initSetPasswordPage (params) {
		this.brandTemplate();
		this.writeTemplate('setPasswordTemplate', {}, this.content);

		const tenantAndId = this.getTenantAndId(params.tenant_id);
		if (!tenantAndId) {
			this.router.resolve('/notfound');
			return;
		}
		this.regSettings.tenantId = tenantAndId.tenantId;
		this.token = tenantAndId.id;

		this.setPasswordPage.form = this.$$('sp-form');
		this.setPasswordPage.password = this.$$('sp-password');
		this.setPasswordPage.confirmPassword = this.$$('sp-confirm-password');
		this.setPasswordPage.error = this.$$('sp-error');
		this.setPasswordPage.button = this.$$('sp-action');
		this.setPasswordPage.showPasswordButtons = this.content.querySelectorAll('div.show-password');

		for (let i = 0, x = this.setPasswordPage.showPasswordButtons.length; i < x; i++) {
			this.setPasswordPage.showPasswordButtons[i].addEventListener('click', () => utils.togglePassword(this.setPasswordPage.showPasswordButtons[i]), false);
		}

		this.setPasswordPage.form.addEventListener('submit', (e) => this.validateSetPasswordForm(e), false);

		this.showPageElements();
	}

	validateSetPasswordForm (e) {
		e.preventDefault();
		if (!utils.validateLength(this.setPasswordPage.password)) {
			utils.toggleErrorMessage(this.setPasswordPage.error, 'validation');
			return false;
		}
		if (!utils.validateMatch(this.setPasswordPage.password, this.setPasswordPage.confirmPassword)) {
			utils.toggleErrorMessage(this.setPasswordPage.error, 'validation');
			return false;
		}
		utils.toggleErrorMessage(this.setPasswordPage.error);
		this.submitSetPasswordForm();
	}

	submitSetPasswordForm () {
		utils.setAttr (this.setPasswordPage.button, 'disabled', 'disabled');
		utils.addClass(this.setPasswordPage.form, 'pending');
		
		const data = {
			tenantId: this.regSettings.tenantId,
			password: this.setPasswordPage.password.value,
			newPassword: this.setPasswordPage.confirmPassword.value,
			passwordResetToken: this.token
		};

		this.xhr = new XMLHttpRequest();
		this.xhr.responseType = 'json';
		this.xhr.open('PUT', this.setPasswordUrl);
		this.xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
		this.xhr.onerror = () => {
			utils.toggleErrorMessage(this.spError, 'server');
  			utils.removeAttr(this.spButton, 'disabled');
		};
		this.xhr.onreadystatechange = this.handleSetPasswordResponse.bind(this);
		this.xhr.send(JSON.stringify(data));
	}

	handleSetPasswordResponse () {
		if (this.xhr.readyState === XMLHttpRequest.DONE) {
			utils.removeClass(this.setPasswordPage.form, 'pending');
			utils.removeAttr(this.setPasswordPage.button, 'disabled');
			if (this.xhr.status !== 200) {
    			if (this.xhr.status === 404) {
					this.router.resolve(`/setpassword/${this.token}/error`);
					return true;
				}
           		utils.toggleErrorMessage(this.setPasswordPage.error, 'server');
    			return true;
			}
			this.passwordResetSuccess = this.xhr.response;
    		this.router.resolve(`/setpassword/${this.token}/success`);
            return true;
		}
		return false;
	}

	visitMy() {
		if (!this.regSettings) {
			utils.doRedirect(window, this.myUrl);
			return;
		}
		if (this.passwordResetSuccess) {
			const domain = `https://${window.TLRAPP.UrlPrefix}${this.passwordResetSuccess.TenantSubdomain || 'my'}.licensingsavi.com`;
			const path = this.passwordResetSuccess.TenantIsTillr3 ? '/authenticate' : '';
			utils.doRedirect(window, domain + path);
			return;
		}
		utils.doRedirect(window, this.myUrl);
	}

	getTenantAndId (str) {
		if (!str || !str.length || str.indexOf('_') === -1) {
			return false;
		}
		const splits = str.split('_');
		if (splits.length !== 2 || !splits[0].length || !splits[1].length) {
			return false;
		}
		return {
			tenantId: splits[0],
	  		id: splits[1]
		}
	}

	getAccessToken (str) {
		if (!str || !str.length || str.indexOf('access_token=') === -1) {
			return false;
		}
		const splits = str.split('access_token=');
		if (splits.length !== 2 || splits[0].length || !splits[1].length) {
			return false;
		}
		return splits[1];
	}

	initRegistrationPage (type, params, query) {
		this.brandTemplate();

		// settings
		this.regSettings.type = type;
		this.regSettings.accessToken = this.getAccessToken(query);

		if (!this.regSettings.accessToken) {
			this.router.resolve('/notfound');
			return;
		}
		if (type === 'invitation' || type === 'signup') {
			const tenantAndId = this.getTenantAndId(params.tenant_id);
			if (!tenantAndId) {
				this.router.resolve('/notfound');
				return;
			}
			this.regSettings.tenantId = tenantAndId.tenantId;
	  		this.regSettings.id = tenantAndId.id;
		} else {
			this.regSettings.id = params.id;
		}

		this.getRegistrationStatus(this.regSettings);
	}

	getRegistrationStatus (obj) {
		const addTenant = obj.type === 'invitation' || obj.type === 'signup' ? `${obj.tenantId}_` : '';
		const url = `${this.registrationUrls.base}` +
					this.registrationUrls[obj.type] +
					`${addTenant}${obj.id}/Status?` +
					`accessToken=${obj.accessToken}`;

		this.xhr = new XMLHttpRequest();
		this.xhr.open('GET', url);
		this.xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
		this.xhr.onerror = () => {
			this.router.resolve('/notfound');
			return;
		};
		this.xhr.onreadystatechange = this.handleGetRegistrationStatusResponse.bind(this);
		this.xhr.send();
		return;
	}

	handleGetRegistrationStatusResponse () {
		if (this.xhr.readyState === XMLHttpRequest.DONE) {
			if (this.xhr.status !== 200) {
				this.router.resolve('/notfound');
				return true;
    		}
    		const response = JSON.parse(this.xhr.response);
    		this.determineRegistrationPageType(response);
            return true;
		}
		return false;
	}

	determineRegistrationPageType (response) {

		// Captain's invite to new tenant
		// {
		//   "TenantId": 705,
		//   "TenantName": "New Accounts 19",
		//   "IsRegistrationRequired": true
		// }

		// New user invited to a tenant
		// {
		//   "TenantName": "New Accounts 19",
		//   "IsRegistrationRequired": true
		// }

		// Existing user invited to a tenant
		// {
		//   "TenantName": "New Accounts 13",
		//   "IsRegistrationRequired": false
		// }

		if (response.ErrorCode && (response.ErrorCode.match('AlreadyAccepted') || response.ErrorCode.match('AlreadyVerified'))) {
			this.router.resolve('/alreadyaccepted');
			return;
		}

		if (response.ErrorCode) {
			this.router.resolve('/notfound');
			return;
		}

		this.regSettings.registrationRequired = response.IsRegistrationRequired;
		this.regSettings.tenantName = response.TenantName;

		if (!this.regSettings.registrationRequired) {
			this.router.resolve('/registrationnotrequired');
			return;
		}

		this.regSettings.firstName = response.FirstName || '';
		this.regSettings.lastName = response.LastName || '';

		this.router.resolve('/registrationrequired');

	}

	initRegistrationNotRequiredPage (obj) {
		this.brandTemplate();
		this.writeTemplate('registrationNotRequiredTemplate', { tenantName: obj.tenantName }, this.content);
		this.content.querySelector('button#action').addEventListener('click', () => this.registerNewUser(obj), false);
		this.showPageElements();
	}

	initRegistrationRequiredPage (obj) {
		this.brandTemplate();
		let template = 'registrationTemplate';
		const env = window.TLRAPP.UrlPrefix.length ? window.TLRAPP.UrlPrefix : 'prod';

		if (obj && obj.tenantId) {
			const ref = this.branding[env][this.regSettings.tenantId] || null;
			if (ref && ref.registrationTemplate && ref.registrationTemplate.length) {
				template = ref.registrationTemplate;
			}
		}

		this.writeTemplate(template, { tenantName: obj.tenantName }, this.content);

		this.registrationPage.form = this.$$('reg-form');
		this.registrationPage.error = this.$$('reg-error');
		this.registrationPage.firstName = this.$$('reg-first-name');
		this.registrationPage.lastName = this.$$('reg-last-name');
		this.registrationPage.password = this.$$('reg-password');
		this.registrationPage.confirmPassword = this.$$('reg-confirm-password');
		this.registrationPage.button = this.$$('reg-action');
		this.registrationPage.showPasswordButtons = this.content.querySelectorAll('div.show-password');

		if (obj.type === 'registration') {
			this.registrationPage.firstName.value = obj.firstName;
			this.registrationPage.lastName.value = obj.lastName;
		}

		for (let i = 0, x = this.registrationPage.showPasswordButtons.length; i < x; i++) {
			this.registrationPage.showPasswordButtons[i].addEventListener('click', () => utils.togglePassword(this.registrationPage.showPasswordButtons[i]), false);
		}

		this.registrationPage.form.addEventListener('submit', (e) => this.validateRegistrationForm(e, template), false);

		this.showPageElements();
	}

	validateRegistrationForm (e, template) {
		e.preventDefault();
		if (!utils.validateLength(this.registrationPage.firstName)) {
			utils.toggleErrorMessage(this.registrationPage.error, 'validation');
			return false;
		}
		if (!utils.validateLength(this.registrationPage.lastName)) {
			utils.toggleErrorMessage(this.registrationPage.error, 'validation');
			return false;
		}
		if (template === 'registrationWithCompanyTemplate') {
			this.registrationPage.companyName = this.$$('reg-company-name');
			if (!utils.validateLength(this.registrationPage.companyName)) {
				utils.toggleErrorMessage(this.registrationPage.error, 'validation');
				return false;
			}
			this.registrationPage.companyNumber = this.$$('reg-company-number');
			// if (!utils.validateLength(this.registrationPage.companyNumber)) {
			// 	utils.toggleErrorMessage(this.registrationPage.error, 'validation');
			// 	return false;
			// }
		}
		if (!utils.validateLength(this.registrationPage.password)) {
			utils.toggleErrorMessage(this.registrationPage.error, 'validation');
			return false;
		}
		if (!utils.validateMatch(this.registrationPage.password, this.registrationPage.confirmPassword)) {
			utils.toggleErrorMessage(this.registrationPage.error, 'validation');
			return false;
		}
		utils.toggleErrorMessage(this.registrationPage.error);
		this.registerNewUser(this.regSettings, template);
	}

	registerNewUser (obj, template = null) {
		if (obj.registrationRequired === true) {
			utils.setAttr(this.registrationPage.button, 'disabled', 'disabled');
			utils.addClass(this.registrationPage.form, 'pending');
		}
		this.registrationData.AccessToken = decodeURIComponent(obj.accessToken);
    if (obj.registrationRequired === true) {
			this.registrationData.NewUserDetails = {
				FirstName: this.registrationPage.firstName.value,
				LastName: this.registrationPage.lastName.value,
				Password: this.registrationPage.password.value
			}
			if (obj.type && obj.type ==='invitation') {
				this.registrationData.NewUserDetails.UserId = this.getNewUUID();
			}
			if (template && template === 'registrationWithCompanyTemplate') {
				this.registrationData.NewUserDetails.CompanyName = this.registrationPage.companyName.value;
				this.registrationData.NewUserDetails.CompanyNumber = this.registrationPage.companyNumber.value;
			}
    }
		this.xhr = new XMLHttpRequest();
		this.xhr.responseType = 'json';
		this.xhr.open('POST', this.getRegistrationUrl(obj));
		this.xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
		this.xhr.onerror = () => {
			utils.toggleErrorMessage(this.registrationPage.error, 'server');
			utils.addClass(this.registrationPage.form, 'pending');
  			utils.removeAttr(this.registrationPage.button, 'disabled');
		};
		this.xhr.onreadystatechange = () => {
			this.handleRegisterNewUserResponse(obj, this.registrationData);
		};
		this.xhr.send(JSON.stringify(this.registrationData));
		return false;
	}

	getRegistrationUrl (obj) {
		const addTenant = obj.type === 'invitation' || obj.type === 'signup' ? `${obj.tenantId}_` : ``;
		const acceptType = obj.type && obj.type === 'signup' ? 'Verify' : 'Accept';
		return	`${this.registrationUrls.base}` +
				this.registrationUrls[obj.type] +
				`${addTenant}` +
				`${obj.id}/${acceptType}`;
	}

	handleRegisterNewUserResponse (obj, data) {
		if (this.xhr.readyState === XMLHttpRequest.DONE) {
			if (obj.registrationRequired === true) {
				utils.removeClass(this.registrationPage.form, 'pending');
				utils.removeAttr(this.registrationPage.button, 'disabled');
			}
			if (this.xhr.status !== 200) {
           		utils.toggleErrorMessage(this.registrationPage.error, 'server');
    			return true;
    		}
    		if (this.xhr.response && this.xhr.response.Message === 'registration already accepted') {
                this.router.resolve('/alreadyaccepted');
                return true;
			}
			this.success(window, data, this.xhr.response);
            return true;
		}
		return false;
	}

	success (win, data, response) {
		const loginHint = data.AccessToken;
		const domain = `https://${window.TLRAPP.UrlPrefix}${response.TenantSubdomain || 'my'}.licensingsavi.com`;
		const path = response.TenantIsTillr3 ? `/authenticate?login_hint=` : `/#!/registration?login_hint=`;
		const successUrl = domain + path + loginHint;
		win.open(successUrl, `_self`);
	}

	getNewUUID () {
        const s = [],
            hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
    }

}

export default RegistrationClass;
